import { Service } from './app.service';
import { DxButtonModule, DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxPopupModule, DxSelectBoxModule } from 'devextreme-angular';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DxGanttModule } from 'devextreme-angular/ui/gantt';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        DxButtonModule,
        DxGanttModule,
        DxDataGridModule,
        DxCheckBoxModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxPopupModule
    ],
    providers: [Service],
    bootstrap: [AppComponent]
})
export class AppModule { }
