import { Resource } from "../models";

export const resources: Resource[] = [{
    id: 1,
    text: 'Plaatser',
}, {
    id: 2,
    text: 'Atelier',
}, {
    id: 3,
    text: 'Productievoorbereiding',
}, {
    id: 4,
    text: 'Sales / Design',
}, {
    id: 5,
    text: 'Boekhouding',
}];
