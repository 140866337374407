import { ResourceAssignment } from "../models";

export const resourceAssignments: ResourceAssignment[] = [
    {
        id: 0,
        taskId: 4,
        resourceId: 3
    },
    {
        id: 1,
        taskId: 6,
        resourceId: 3
    },
    {
        id: 2,
        taskId: 8,
        resourceId: 3
    },
    {
        id: 3,
        taskId: 9,
        resourceId: 3
    },
    {
        id: 4,
        taskId: 11,
        resourceId: 3
    },
    {
        id: 5,
        taskId: 14,
        resourceId: 3
    },
    {
        id: 6,
        taskId: 16,
        resourceId: 3
    },
    {
        id: 7,
        taskId: 18,
        resourceId: 3
    },
    {
        id: 8,
        taskId: 22,
        resourceId: 1
    },
    {
        id: 9,
        taskId: 24,
        resourceId: 1
    },
    {
        id: 10,
        taskId: 27,
        resourceId: 1
    },
    {
        id: 11,
        taskId: 30,
        resourceId: 1
    },
    {
        id: 12,
        taskId: 33,
        resourceId: 1
    },


];
