import { Dependency } from "../models";

export const dependencies: Dependency[] = [
    {
        id: 1,
        predecessorId: 8,
        successorId: 9,
        type: 1,
    }
];
