import { Task } from '../models';
export const tasks: Task[] = [
    {
        id: 1,
        parentId: 0,
        title: 'Productievoorbereiding',

    },

    // DYLAN
    {
        id: 2,
        parentId: 1,
        title: 'Dylan Van Meenen',
        employee: true,
    },
    {
        id: 3,
        parentId: 2,
        title: 'Pauline Vanthournhout - Tamarisklaan FASE 2',
    },
    {
        id: 4,
        parentId: 3,
        title: 'DYLAN pvb',
        start: new Date('2022-12-02T10:00:00.000Z'),
        end: new Date('2022-12-15T09:00:00.000Z'),
        progress: 60,
    },
    {
        id: 5,
        parentId: 2,
        title: 'Scratch - Thiel',
    },
    {
        id: 6,
        parentId: 5,
        title: 'DYLAN pvb',
        start: new Date('2022-12-16T08:00:00.000Z'),
        end: new Date('2022-12-16T17:00:00.000Z'),
        progress: 10,
    },
    {
        id: 7,
        parentId: 2,
        title: 'Cayseele Michel - Zeedijk 504 2e',
    },
    {
        id: 8,
        parentId: 7,
        title: 'OPMETING DYLAN',
        start: new Date('2022-12-15T08:00:00.000Z'),
        end: new Date('2022-12-15T12:00:00.000Z'),
        progress: 0,
    },
    {
        id: 9,
        parentId: 7,
        title: 'DEADLINE OFFERTE OPMAKEN',
        start: new Date('2022-12-15T13:00:00.000Z'),
        end: new Date('2022-12-15T17:00:00.000Z'),
        progress: 0,
    },
    {
        id: 10,
        parentId: 2,
        title: 'Scratch - Immo Bis',
    },
    {
        id: 11,
        parentId: 10,
        title: 'Afspraak met klant',
        start: new Date('2022-12-14T08:00:00.000Z'),
        end: new Date('2022-12-14T17:00:00.000Z'),
        progress: 0,
    },

    // JUUL
    {
        id: 12,
        parentId: 1,
        title: 'Juul Sobry',
    },
    {
        id: 13,
        parentId: 12,
        title: 'Simon De Burbure - Cracco',
    },
    {
        id: 14,
        parentId: 13,
        title: 'JUUL pvb',
        start: new Date('2023-01-23T10:00:00.000Z'),
        end: new Date('2023-02-07T09:00:00.000Z'),
        progress: 0
    },
    {
        id: 15,
        parentId: 12,
        title: 'Willems - inrichting woning - Hortensialaan',
    },
    {
        id: 16,
        parentId: 15,
        title: 'Aanpassing offerte',
        start: new Date('2022-12-14T10:00:00.000Z'),
        end: new Date('2022-12-15T09:00:00.000Z'),
        progress: 100
    },
    {
        id: 17,
        parentId: 12,
        title: 'Dupont - Res. Elite 4de',
    },
    {
        id: 18,
        parentId: 17,
        title: 'JUUL pvb',
        start: new Date('2022-12-16T10:00:00.000Z'),
        end: new Date('2022-12-17T09:00:00.000Z'),
        progress: 100
    },
    // Plaatser
    {
        id: 19,
        parentId: 0,
        title: 'Plaatser',
    },
    // DIRK
    {
        id: 20,
        parentId: 19,
        title: 'Dirk De Fauw',
    },
    {
        id: 21,
        parentId: 20,
        title: 'Vanbelleghem Magalie',
    },
    {
        id: 22,
        parentId: 21,
        title: 'Installatie',
        start: new Date('2022-12-16T10:00:00.000Z'),
        end: new Date('2022-12-17T09:00:00.000Z'),
        progress: 100
    },
    {
        id: 23,
        parentId: 20,
        title: 'Dhr. Projects - Res. Zouteduin 0.2 - Demarche',
    },
    {
        id: 24,
        parentId: 23,
        title: 'Installatie',
        start: new Date('2022-12-19T09:00:00.000Z'),
        end: new Date('2022-12-19T17:00:00.000Z'),
        progress: 0
    },
    // ANTHONY
    {
        id: 25,
        parentId: 19,
        title: 'Anthony Van den Broeck',
    },
    {
        id: 26,
        parentId: 25,
        title: 'Govaert Vanhoutte - Lathouers',
    },
    {
        id: 27,
        parentId: 26,
        title: 'Installatie',
        start: new Date('2022-11-14T09:00:00.000Z'),
        end: new Date('2023-01-20T17:00:00.000Z'),
        progress: 30
    },
    // Jan Max
    {
        id: 28,
        parentId: 19,
        title: 'Jan Max',
    },
    {
        id: 29,
        parentId: 28,
        title: 'Govaert Vanhoutte - Lathouers',
    },
    {
        id: 30,
        parentId: 29,
        title: 'Installatie',
        start: new Date('2022-10-24T09:00:00.000Z'),
        end: new Date('2023-01-20T17:00:00.000Z'),
        progress: 40
    },
    // Bart
    {
        id: 31,
        parentId: 19,
        title: 'Bart',
    },
    {
        id: 32,
        parentId: 31,
        title: 'Govaert Vanhoutte - Lathouers',
    },
    {
        id: 33,
        parentId: 32,
        title: 'Installatie',
        start: new Date('2022-10-24T09:00:00.000Z'),
        end: new Date('2023-01-20T17:00:00.000Z'),
        progress: 25
    },


];
