
import { tasks, resources, resourceAssignments, dependencies } from './data';
import { Injectable } from '@angular/core';
import { Task, Resource, ResourceAssignment, Dependency } from './models';


@Injectable()
export class Service {
    getTasks(): Task[] {
        return tasks;
    }

    getDependencies(): Dependency[] {
        return dependencies;
    }

    getResources(): Resource[] {
        return resources;
    }

    getResourceAssignments(): ResourceAssignment[] {
        return resourceAssignments;
    }
}
