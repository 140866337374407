import { Component } from '@angular/core';
import { Task, Dependency, Resource, ResourceAssignment } from './models';
import { Service } from './app.service';
import { locale, loadMessages } from 'devextreme/localization';

// import 'devextreme/localization/globalize/number';
// import 'devextreme/localization/globalize/date';
// import 'devextreme/localization/globalize/currency';
// import 'devextreme/localization/globalize/message';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [Service],
    preserveWhitespaces: true,
})

export class AppComponent {

    tasks: Task[];
    dependencies: Dependency[];
    resources: Resource[];
    resourceAssignments: ResourceAssignment[];
    customButtonOptions: any;
    popupVisible: boolean = false;
    currentTime!: Date;
    locale!: string;

    constructor(service: Service) {
        this.tasks = service.getTasks();
        this.dependencies = service.getDependencies();
        this.resources = service.getResources();
        this.resourceAssignments = service.getResourceAssignments();
        this.customButtonOptions = {
            text: 'About',
            icon: 'info',
            stylingMode: 'text',
            onClick: () => {
                this.popupVisible = true;
            },
        };
        this.currentTime = new Date();
        this.setLocale('nl');
        locale(navigator.language);
    }

    getLocale() {
        const locale = sessionStorage.getItem('locale');
        return locale != null ? locale : 'en';
    }
    setLocale(locale: string) {
        sessionStorage.setItem('locale', locale);
    }

}
